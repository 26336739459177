import { List } from "@phosphor-icons/react";
import { Link, Outlet } from "@remix-run/react";

import { FlexRow } from "~/components/flex-row";
import { Logo } from "~/components/logo";
import { buttonVariants } from "~/components/ui/button";
import { Separator } from "~/components/ui/separator";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTitle,
  SheetTrigger,
} from "~/components/ui/sheet";
import { cn } from "~/lib/utils";
import { useOptionalUser } from "~/utils/misc";
import { AUTH_LOGIN, DASHBOARD_PATH, TALK_TO_US_URL } from "~/utils/paths";

export default function MarketingLandingPage() {
  const user = useOptionalUser();

  const navigation = [
    { href: "/#product", name: "Product" },
    // { href: "#customers", name: "Customers" },
    { href: "/#use-cases", name: "Use Cases" },
    { href: "/#features", name: "Features" }, // to be replaced by “Product” and “Customers”
    ...(user == null
      ? [
          { href: AUTH_LOGIN(), name: "Log In" },
          { href: TALK_TO_US_URL, isPrimary: true, name: "Book a Demo" },
        ]
      : [{ href: DASHBOARD_PATH, isPrimary: true, name: "Dashboard" }]),
  ];

  return (
    <div>
      <div className="sticky inset-x-0 top-5 z-50 mt-px h-0 sm:top-10 sm:mt-0 3xl:top-14">
        <FlexRow className="mx-auto max-w-screen-3xl items-start justify-between gap-4 px-4 sm:px-10">
          <FlexRow className="items-center gap-2 rounded-xl bg-background/80 p-1 pl-2 pr-4 drop-shadow-lg backdrop-blur">
            <Logo className="ml-2.5 mr-1.5" variant="marketing" />
            <Separator
              className="mr-1 hidden h-10 bg-foreground/10 xs:block"
              decorative
              orientation="vertical"
            />
            <span className="hidden font-serif text-lg font-light italic leading-5 xs:inline">
              The <span className="font-normal not-italic">New Standard</span>{" "}
              in{" "}
              <span className="font-normal not-italic">
                Qualitative Research
              </span>
            </span>
          </FlexRow>

          {/* Right navigation large screens */}
          <div className="hidden items-center gap-1 rounded-xl bg-background/80 p-1 drop-shadow-lg backdrop-blur lg:flex">
            {navigation.map((item) => (
              <Link
                className={cn(
                  buttonVariants({
                    size: "navbar",
                    variant: item.isPrimary === true ? "default" : "ghost",
                  }),
                  item.isPrimary === true && "font-semibold",
                  "h-10 rounded-lg",
                )}
                key={item.name}
                to={item.href}
              >
                {item.name}
              </Link>
            ))}
          </div>

          {/* Right navigation small screens */}
          <Sheet>
            <SheetTrigger asChild>
              <button className="flex items-center rounded-lg bg-background/90 p-2 backdrop-blur lg:hidden">
                <List size={32} />
              </button>
            </SheetTrigger>
            <SheetContent className="m-4 rounded-lg p-0" side="top" size="full">
              <div className="flex border-b p-2">
                <SheetTitle>
                  <Logo variant="marketing" />
                </SheetTitle>
              </div>
              <div className="flex flex-col items-start space-y-2 p-6">
                {navigation.map((item) => (
                  <SheetClose asChild key={item.name}>
                    <Link
                      className={cn(
                        buttonVariants({
                          size: "navbar",
                          variant:
                            item.isPrimary === true ? "default" : "ghost",
                        }),
                        "w-full justify-start rounded-lg text-2xl",
                        item.isPrimary !== true && "text-muted-foreground",
                      )}
                      to={item.href}
                    >
                      {item.name}
                    </Link>
                  </SheetClose>
                ))}
              </div>
            </SheetContent>
          </Sheet>
        </FlexRow>
      </div>
      <Outlet />
    </div>
  );
}
